import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Heading, Text, Link } from 'rebass';

const CookiesPolicy: React.FC<any> = () => (
  <Box p={['3rem', '9rem']} overflow={'auto'} height={'100%'}>
    <Heading my={3} as={'h1'} fontSize={6}>
      Cookies Policy
    </Heading>
    <Text my={4}>Updated at 20/12/2020</Text>
    <Heading my={4} as={'h2'}>
      Definitions and key terms
    </Heading>
    <Text my={2}>For this Privacy Policy:</Text>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Cookie:
      </Text>{' '}
      small amount of data generated by a website and saved by your web browser. It is used to
      identify your browser, provide analytics, remember information about you such as your language
      preference or login information.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Company:
      </Text>{' '}
      when this policy mentions “Company,” “we,” “us,” or “our,” it refers to 3dreact that is
      responsible for your information under this Privacy Policy.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Country:
      </Text>{' '}
      where 3dreact or the owners/founders of 3dreact are based, in this case is Bulgaria.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Customer:
      </Text>{' '}
      refers to the company, organization or person that signs up to use the 3dreact Service to
      manage the relationships with your consumers or service users.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Device:
      </Text>{' '}
      any internet connected device such as a phone, tablet, computer or any other device that can
      be used to visit 3dreact and use the services.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        IP address:
      </Text>{' '}
      Every device connected to the Internet is assigned a number known as an Internet protocol (IP)
      address. These numbers are usually assigned in geographic blocks. An IP address can often be
      used to identify the location from which a device is connecting to the Internet.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Personnel:
      </Text>{' '}
      refers to those individuals who are employed by 3dreact or are under contract to perform a
      service on behalf of one of the parties.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Personal Data:
      </Text>{' '}
      any information that directly, indirectly, or in connection with other information — including
      a personal identification number — allows for the identification or identifiability of a
      natural person.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Service:
      </Text>{' '}
      refers to the service provided by 3dreact as described in the relative terms (if available)
      and on this platform.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Third-party service:
      </Text>{' '}
      refers to advertisers, contest sponsors, promotional and marketing partners, and others who
      provide our content or whose products or services we think may interest you.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Website:
      </Text>{' '}
      3dreact’s site, which can be accessed via this URL: https://www.3dreact.com .
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        You:
      </Text>{' '}
      a person or entity that is registered with 3dreact to use the Services.
    </Box>
    <Heading my={4} as={'h2'}>
      Introduction
    </Heading>
    <Text my={2}>
      This Cookie Policy explains how 3dreact and its affiliates (collectively "3dreact", "we",
      "us", and "ours"), use cookies and similar technologies to recognize you when you visit our
      platform, including without limitation https://3dreact.com and any related URLs, mobile or
      localized versions and related domains / sub-domains ("Websites"). It explains what these
      technologies are and why we use them, as well as the choices for how to control them.
    </Text>
    <Heading my={4} as={'h2'}>
      What is a cookie?
    </Heading>
    <Text my={2}>
      A cookie is a small text file that is stored on your computer or other internet connected
      device in order to identify your browser, provide analytics, remember information about you
      such as your language preference or login information. They're completely safe and can't be
      used to run programs or deliver viruses to your device.
    </Text>
    <Heading my={4} as={'h2'}>
      Why do we use cookies?
    </Heading>
    <Text my={2}>
      We use first party and/or third party cookies on our platform for various purposes such as:
    </Text>
    <Box my={2} variant={'list'}>
      To facilitate the operation and functionality of our platform.
    </Box>
    <Box my={2} variant={'list'}>
      To improve your experience of our platform and make navigating around them quicker and easier.
    </Box>
    <Box my={2} variant={'list'}>
      To allow us to make a bespoke user experience for you and for us to understand what is useful
      or of interest to you.
    </Box>
    <Box my={2} variant={'list'}>
      To analyze how our platform is used and how best we can customize it.
    </Box>
    <Box my={2} variant={'list'}>
      To identify future prospects and personalize marketing and sales interactions with it.
    </Box>
    <Box my={2} variant={'list'}>
      To facilitate the tailoring of online advertising to your interests.
    </Box>
    <Heading my={4} as={'h2'}>
      What type of cookies does 3dreact use?
    </Heading>
    <Text my={2}>
      Cookies can either be session cookies or persistent cookies. A session cookie expires
      automatically when you close your browser. A persistent cookie will remain until it expires or
      you delete your cookies. Expiration dates are set in the cookies themselves; some may expire
      after a few minutes while others may expire after multiple years. Cookies placed by the
      website you’re visiting are called “first party cookies”.
    </Text>
    <Text my={2}>
      Strictly Necessary cookies are necessary for our platform to function and cannot be switched
      off in our systems. They are essential in order to enable you to navigate around the platform
      and use its features. If you remove or disable these cookies, we cannot guarantee that you
      will be able to use our platform.
    </Text>
    <Text my={2}>We use the following types of cookies in our platform:</Text>
    <Heading my={4} as={'h2'}>
      Essential Cookies
    </Heading>
    <Text my={2}>
      We use essential cookies to make our platform work. These cookies are strictly necessary to
      enable core functionality such as security, network management, your cookie preferences and
      accessibility. Without them you wouldn't be able to use basic services. You may disable these
      by changing your browser settings, but this may affect how the Websites function.
    </Text>
    <Heading my={4} as={'h2'}>
      Performance and Functionality Cookies
    </Heading>
    <Text my={2}>
      These cookies are used to enhance the performance and functionality of our platform but are
      non-essential to their use. However, without these cookies, certain functionality like videos
      may become unavailable or you would be required to enter your login details every time you
      visit the platform as we would not be able to remember that you had logged in previously.
    </Text>
    <Heading my={4} as={'h2'}>
      Marketing Cookies
    </Heading>
    <Text my={2}>
      These account-based marketing cookies enable us to identify future prospects and personalize
      sales and marketing interactions with them.
    </Text>
    <Heading my={4} as={'h2'}>
      Analytics and Customization Cookies
    </Heading>
    <Text my={2}>
      These cookies collect information that is used to help us understand how our platform is being
      used or how effective our marketing campaigns are, or to help us customize our platform for
      you.
    </Text>
    <Text my={2}>
      We use cookies served by Google Analytics to collect limited data directly from end-user
      browsers to enable us to better understand your use of our platform. Further information on
      how Google collects and uses this data can be found at:
      https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported
      analytics on our Websites by visiting: https://tools.google.com/dlpage/gaoptout.
    </Text>
    <Heading my={4} as={'h2'}>
      Advertising Cookies
    </Heading>
    <Text my={2}>
      These cookies collect information over time about your online activity on the platform and
      other online services to make online advertisements more relevant and effective to you. This
      is known as interest-based advertising. They also perform functions like preventing the same
      ad from continuously reappearing and ensuring that ads are properly displayed for advertisers.
      Without cookies, it’s really hard for an advertiser to reach its audience, or to know how many
      ads were shown and how many clicks they received.
    </Text>
    <Heading my={4} as={'h2'}>
      Social Media Cookies
    </Heading>
    <Text my={2}>
      These cookies are used when you share information using a social media sharing button or
      “like” button on our Site or you link your account or engage with our content on or through a
      social networking website such as Facebook, Twitter or Google+. The social network will record
      that you have done this. These cookies may also include certain code that has been placed on
      the platform to help track conversions from ads, optimize ads based on collected data, build
      targeted audiences for future ads, and remarket to qualified users who have already taken
      certain action on the platform.
    </Text>
    <Heading my={4} as={'h2'}>
      Third Party Cookies
    </Heading>
    <Text my={2}>
      Some cookies that have been set on our platform are not set on a first party basis by 3dreact.
      The Websites can be embedded with content from third parties to serve advertising. These third
      party service providers may set their own cookies on your web browser. Third party service
      providers control many of the performance and functionality, advertising, marketing and
      analytics cookies described above. We do not control the use of these third party cookies as
      cookies can only be accessed by the third party that originally set them.
    </Text>
    <Heading my={4} as={'h2'}>
      How you can manage cookies?
    </Heading>
    <Text my={2}>
      Most browsers allow you to control cookies through their 'settings' preferences. However, if
      you limit the ability of websites to set cookies, you may worsen your overall user experience,
      since it will no longer be personalized to you. It may also stop you from saving customized
      settings like login information. Browser manufacturers provide help pages relating to cookie
      management in their products.
    </Text>
    <Text my={2}>
      Browser manufacturers provide help pages relating to cookie management in their products.
      Please see below for more information.
    </Text>
    <Box my={2} variant={'list'}>
      Google Chrome
    </Box>
    <Box my={2} variant={'list'}>
      Microsoft Ege
    </Box>
    <Box my={2} variant={'list'}>
      Mozilla Firefox
    </Box>
    <Box my={2} variant={'list'}>
      Safari (Desktop)
    </Box>
    <Box my={2} variant={'list'}>
      Safari (Mobile)
    </Box>
    <Box my={2} variant={'list'}>
      Android Browser
    </Box>
    <Box my={2} variant={'list'}>
      Opera / Opera Mobile
    </Box>
    <Heading my={4} as={'h2'}>
      Blocking and disabling cookies and similar technologies
    </Heading>
    <Text my={2}>
      Wherever you're located you may also set your browser to block cookies and similar
      technologies, but this action may block our essential cookies and prevent our website from
      functioning properly, and you may not be able to fully utilize all of its features and
      services. You should also be aware that you may also lose some saved information (e.g. saved
      login details, site preferences) if you block cookies on your browser. Different browsers make
      different controls available to you. Disabling a cookie or category of cookie does not delete
      the cookie from your browser, you will need to do this yourself from within your browser, you
      should visit your browser's help menu for more information.
    </Text>
    <Heading my={4} as={'h2'}>
      Changes To Our Cookie Policy
    </Heading>
    <Text my={2}>
      We may change our Service and policies, and we may need to make changes to this Cookie Policy
      so that they accurately reflect our Service and policies. Unless otherwise required by law, we
      will notify you (for example, through our Service) before we make changes to this Cookie
      Policy and give you an opportunity to review them before they go into effect. Then, if you
      continue to use the Service, you will be bound by the updated Cookie Policy. If you do not
      want to agree to this or any updated Cookie Policy, you can delete your account.
    </Text>
    <Heading my={4} as={'h2'}>
      Your Consent
    </Heading>
    <Text my={2}>
      By using our platform, registering an account, or making a purchase, you hereby consent to our
      Cookie Policy and agree to its terms.
    </Text>
    <Heading my={4} as={'h2'}>
      Contact Us
    </Heading>
    <Text my={3}>Don't hesitate to contact us if you have any questions.</Text>
    <Box my={2} variant={'list'}>
      Via Email: <Link href="mailto:3dreactprj@gmail.com">3dreactprj@gmail.com</Link>
    </Box>
    <Box my={2} variant={'list'}>
      Via this Link: <RouterLink to="/about"> https://3dreact.com/about</RouterLink>
    </Box>
  </Box>
);

export default CookiesPolicy;
