import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Heading, Text, Link } from 'rebass';

const PrivacyPolicy: React.FC<any> = () => (
  <Box p={['3rem', '9rem']} overflow={'auto'} height={'100%'}>
    <Heading my={3} as={'h1'} fontSize={6}>
      Privacy Policy
    </Heading>
    <Text my={4}>Updated at 20/12/2020</Text>
    <Text my={4}>
      3dreact. ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy
      explains how your personal information is collected, used, and disclosed by 3dreact. This
      Privacy Policy applies to our website, https://www.3dreact.com , and its associated subdomains
      (collectively, our "Service"). By accessing or using our Service, you signify that you have
      read, understood, and agree to our collection, storage, use, and disclosure of your personal
      information as described in this Privacy Policy and our Terms of Service.
    </Text>
    <Heading my={4} as={'h2'}>
      Definitions and key terms
    </Heading>
    <Text my={2}>For this Privacy Policy:</Text>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Cookie:
      </Text>{' '}
      small amount of data generated by a website and saved by your web browser. It is used to
      identify your browser, provide analytics, remember information about you such as your language
      preference or login information.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Company:
      </Text>{' '}
      when this policy mentions “Company,” “we,” “us,” or “our,” it refers to 3dreact that is
      responsible for your information under this Privacy Policy.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Country:
      </Text>{' '}
      where 3dreact or the owners/founders of 3dreact are based, in this case is Bulgaria.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Customer:
      </Text>{' '}
      refers to the company, organization or person that signs up to use the 3dreact Service to
      manage the relationships with your consumers or service users.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Device:
      </Text>{' '}
      any internet connected device such as a phone, tablet, computer or any other device that can
      be used to visit 3dreact and use the services.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        IP address:
      </Text>{' '}
      Every device connected to the Internet is assigned a number known as an Internet protocol (IP)
      address. These numbers are usually assigned in geographic blocks. An IP address can often be
      used to identify the location from which a device is connecting to the Internet.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Personnel:
      </Text>{' '}
      refers to those individuals who are employed by 3dreact or are under contract to perform a
      service on behalf of one of the parties.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Personal Data:
      </Text>{' '}
      any information that directly, indirectly, or in connection with other information — including
      a personal identification number — allows for the identification or identifiability of a
      natural person.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Service:
      </Text>{' '}
      refers to the service provided by 3dreact as described in the relative terms (if available)
      and on this platform.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Third-party service:
      </Text>{' '}
      refers to advertisers, contest sponsors, promotional and marketing partners, and others who
      provide our content or whose products or services we think may interest you.
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        Website:
      </Text>{' '}
      3dreact’s site, which can be accessed via this URL: https://www.3dreact.com .
    </Box>
    <Box variant="list">
      <Text fontWeight={'bold'} display={'inline'}>
        You:
      </Text>{' '}
      a person or entity that is registered with 3dreact to use the Services.
    </Box>
    <Heading my={4} as={'h2'}>
      What Information Do We Collect?
    </Heading>
    <Text my={2}>
      We collect information from you when you visit our service, register, place an order,
      subscribe to our newsletter, respond to a survey or fill out a form.
    </Text>
    <Box variant="list">Name / Username</Box>
    <Box variant="list">Phone Numbers</Box>
    <Box variant="list">Email Addresses</Box>
    <Box variant="list">Mailing Addresses</Box>
    <Text my={2}>
      We also collect information from mobile devices for a better user experience, although these
      features are completely optional:
    </Text>
    <Heading my={4} as={'h2'}>
      How Do We Use The Information We Collect?
    </Heading>
    <Text>
      Any of the information we collect from you may be used in one of the following ways:
    </Text>
    <Box variant="list">
      To personalize your experience (your information helps us to better respond to your individual
      needs)
    </Box>
    <Box variant="list">
      To improve our service (we continually strive to improve our service offerings based on the
      information and feedback we receive from you)
    </Box>
    <Box variant="list">
      To improve customer service (your information helps us to more effectively respond to your
      customer service requests and support needs)
    </Box>
    <Box variant="list">To send periodic emails</Box>
    <Heading my={4} as={'h2'}>
      When do we use customer information from third parties?
    </Heading>
    <Box>
      We receive some information from the third parties when you contact us. For example, when you
      submit your email address to us to show interest in becoming our customer, we receive
      information from a third party that provides automated fraud detection services to us. We also
      occasionally collect information that is made publicly available on social media websites. You
      can control how much of your information social media websites make public by visiting these
      websites and changing your privacy settings.
    </Box>
    <Heading my={4} as={'h2'}>
      Do we share the information we collect with third parties?
    </Heading>
    <Text my={2}>
      We may share the information that we collect, both personal and non-personal, with third
      parties such as advertisers, contest sponsors, promotional and marketing partners, and others
      who provide our content or whose products or services we think may interest you. We may also
      share it with our current and future affiliated companies and business partners, and if we are
      involved in a merger, asset sale or other business reorganization, we may also share or
      transfer your personal and non-personal information to our successors-in-interest.
    </Text>
    <Text my={2}>
      We may engage trusted third party service providers to perform functions and provide services
      to us, such as hosting and maintaining our servers and our service, database storage and
      management, e-mail management, storage marketing, credit card processing, customer service and
      fulfilling orders for products and services you may purchase through our platform. We will
      likely share your personal information, and possibly some non-personal information, with these
      third parties to enable them to perform these services for us and for you.
    </Text>
    <Text my={2}>
      We may share portions of our log file data, including IP addresses, for analytics purposes
      with third parties such as web analytics partners, application developers, and ad networks. If
      your IP address is shared, it may be used to estimate general location and other
      technographics such as connection speed, whether you have visited the service in a shared
      location, and type of device used to visit the service. They may aggregate information about
      our advertising and what you see on the service and then provide auditing, research and
      reporting for us and our advertisers.
    </Text>
    <Text my={2}>
      We may also disclose personal and non-personal information about you to government or law
      enforcement officials or private parties as we, in our sole discretion, believe necessary or
      appropriate in order to respond to claims, legal process (including subpoenas), to protect our
      rights and interests or those of a third party, the safety of the public or any person, to
      prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply
      with applicable court orders, laws, rules and regulations.
    </Text>
    <Heading my={4} as={'h2'}>
      Where and when is information collected from customers and end users?
    </Heading>
    <Text my={2}>
      We will collect personal information that you submit to us. We may also receive personal
      information about you from third parties as described above.
    </Text>
    <Heading my={4} as={'h2'}>
      How Do We Use Your Email Address?
    </Heading>
    <Text my={2}>
      By submitting your email address on this website, you agree to receive emails from us. You can
      cancel your participation in any of these email lists at any time by clicking on the opt-out
      link or other unsubscribe option that is included in the respective email. We only send emails
      to people who have authorized us to contact them, either directly, or through a third party.
      We do not send unsolicited commercial emails, because we hate spam as much as you do. By
      submitting your email address, you also agree to allow us to use your email address for
      customer audience targeting on sites like Facebook, where we display custom advertising to
      specific people who have opted-in to receive communications from us. Email addresses submitted
      only through the order processing page will be used for the sole purpose of sending you
      information and updates pertaining to your order. If, however, you have provided the same
      email to us through another method, we may use it for any of the purposes stated in this
      Policy. Note: If at any time you would like to unsubscribe from receiving future emails, we
      include detailed unsubscribe instructions at the bottom of each email.
    </Text>
    <Heading my={4} as={'h2'}>
      Could my information be transferred to other countries?
    </Heading>
    <Text my={2}>
      We are incorporated in Bulgaria. Information collected via our website, through direct
      interactions with you, or from use of our help services may be transferred from time to time
      to our offices or personnel, or to third parties, located throughout the world, and may be
      viewed and hosted anywhere in the world, including countries that may not have laws of general
      applicability regulating the use and transfer of such data. To the fullest extent allowed by
      applicable law, by using any of the above, you voluntarily consent to the trans- border
      transfer and hosting of such information.
    </Text>
    <Heading my={4} as={'h2'}>
      Is the information collected through our service secure?
    </Heading>
    <Text my={2}>
      We take precautions to protect the security of your information. We have physical, electronic,
      and managerial procedures to help safeguard, prevent unauthorized access, maintain data
      security, and correctly use your information. However, neither people nor security systems are
      foolproof, including encryption systems. In addition, people can commit intentional crimes,
      make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to
      protect your personal information, we cannot guarantee its absolute security. If applicable
      law imposes any non-disclaimable duty to protect your personal information, you agree that
      intentional misconduct will be the standards used to measure our compliance with that duty.
    </Text>
    <Heading my={4} as={'h2'}>
      Can I update or correct my information?
    </Heading>
    <Text my={2}>
      The rights you have to request updates or corrections to the information we collect depend on
      your relationship with us. Personnel may update or correct their information as detailed in
      our internal company employment policies. Customers have the right to request the restriction
      of certain uses and disclosures of personally identifiable information as follows. You can
      contact us in order to (1) update or correct your personally identifiable information, (2)
      change your preferences with respect to communications and other information you receive from
      us, or (3) delete the personally identifiable information maintained about you on our systems
      (subject to the following paragraph), by cancelling your account. Such updates, corrections,
      changes and deletions will have no effect on other information that we maintain, or
      information that we have provided to third parties in accordance with this Privacy Policy
      prior to such update, correction, change or deletion. To protect your privacy and security, we
      may take reasonable steps (such as requesting a unique password) to verify your identity
      before granting you profile access or making corrections. You are responsible for maintaining
      the secrecy of your unique password and account information at all times. You should be aware
      that it is not technologically possible to remove each and every record of the information you
      have provided to us from our system. The need to back up our systems to protect information
      from inadvertent loss means that a copy of your information may exist in a non-erasable form
      that will be difficult or impossible for us to locate. Promptly after receiving your request,
      all personal information stored in databases we actively use, and other readily searchable
      media will be updated, corrected, changed or deleted, as appropriate, as soon as and to the
      extent reasonably and technically practicable. If you are an end user and wish to update,
      delete, or receive any information we have about you, you may do so by contacting the
      organization of which you are a customer.
    </Text>
    <Heading my={4} as={'h2'}>
      Sale of Business
    </Heading>
    <Text my={2}>
      We reserve the right to transfer information to a third party in the event of a sale, merger
      or other transfer of all or substantially all of the assets of us or any of its Corporate
      Affiliates (as defined herein), or that portion of us or any of its Corporate Affiliates to
      which the Service relates, or in the event that we discontinue our business or file a petition
      or have filed against us a petition in bankruptcy, reorganization or similar proceeding,
      provided that the third party agrees to adhere to the terms of this Privacy Policy.
    </Text>
    <Heading my={4} as={'h2'}>
      Affiliates
    </Heading>
    <Text my={2}>
      We may disclose information (including personal information) about you to our Corporate
      Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or
      entity which directly or indirectly controls, is controlled by or is under common control with
      us, whether by ownership or otherwise. Any information relating to you that we provide to our
      Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the
      terms of this Privacy Policy.
    </Text>
    <Heading my={4} as={'h2'}>
      How Long Do We Keep Your Information?
    </Heading>
    <Text my={2}>
      We keep your information only so long as we need it to provide service to you and fulfill the
      purposes described in this policy. This is also the case for anyone that we share your
      information with and who carries out services on our behalf. When we no longer need to use
      your information and there is no need for us to keep it to comply with our legal or regulatory
      obligations, we'll either remove it from our systems or depersonalize it so that we can't
      identify you.
    </Text>
    <Heading my={4} as={'h2'}>
      How Do We Protect Your Information?
    </Heading>
    <Text my={2}>
      We implement a variety of security measures to maintain the safety of your personal
      information when you place an order or enter, submit, or access your personal information. We
      offer the use of a secure server. All supplied sensitive/credit information is transmitted via
      Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers
      database only to be accessible by those authorized with special access rights to such systems,
      and are required to keep the information confidential. After a transaction, your private
      information (credit cards, social security numbers, financials, etc.) is never kept on file.
      We cannot, however, ensure or warrant the absolute security of any information you transmit to
      us or guarantee that your information on the Service may not be accessed, disclosed, altered,
      or destroyed by a breach of any of our physical, technical, or managerial safeguards.
    </Text>
    <Heading my={4} as={'h2'}>
      Governing Law
    </Heading>
    <Text my={2}>
      The laws of Bulgaria, excluding its conflicts of law rules, shall govern this Agreement and
      your use of our service. Your use of our service may also be subject to other local, state,
      national, or international laws.
    </Text>
    <Heading my={4} as={'h2'}>
      Your Consent
    </Heading>
    <Text my={2}>
      By using our service, registering an account, you consent to this Privacy Policy.
    </Text>
    <Heading my={4} as={'h2'}>
      Links to Other Websites
    </Heading>
    <Text my={2}>
      This Privacy Policy applies only to the Services. The Services may contain links to other
      websites not operated or controlled by . We are not responsible for the content, accuracy or
      opinions expressed in such websites, and such websites are not investigated, monitored or
      checked for accuracy or completeness by us. Please remember that when you use a link to go
      from the Services to another website, our Privacy Policy is no longer in effect. Your browsing
      and interaction on any other website, including those that have a link on our platform, is
      subject to that website’s own rules and policies. Such third parties may use their own cookies
      or other methods to collect information about you.
    </Text>
    <Heading my={4} as={'h2'}>
      Cookies
    </Heading>
    <Text my={2}>
      We use "Cookies" to identify the areas of our website that you have visited. A Cookie is a
      small piece of data stored on your computer or mobile device by your web browser. We use
      Cookies to personalize the Content that you see on our website. Most web browsers can be set
      to disable the use of Cookies. However, if you disable Cookies, you may not be able to access
      functionality on our website correctly or at all. We never place Personally Identifiable
      Information in Cookies.
    </Text>
    <Heading my={4} as={'h2'}>
      Advertising
    </Heading>
    <Text my={2}>
      Advertising keeps us and many of the websites and services you use free of charge. We work
      hard to make sure that ads are safe, unobtrusive, and as relevant as possible.
    </Text>
    <Heading my={4} as={'h2'}>
      Cookies for Advertising
    </Heading>
    <Text my={2}>
      Cookies help to make advertising more effective. Without cookies, it's really hard for an
      advertiser to reach its audience, or to know how many ads were shown and how many clicks they
      received.
    </Text>
    <Heading my={4} as={'h2'}>
      Remarketing Services
    </Heading>
    <Text my={2}>
      We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or
      retargeting) is the practice of serving ads across the internet to people who have already
      visited your website. It allows your company to seem like they're “following” people around
      the internet by serving ads on the websites and platforms they use most.
    </Text>
    <Heading my={4} as={'h2'}>
      Kids' Privacy
    </Heading>
    <Text my={2}>
      We collect information from kids under the age of 13 just to better our services. If You are a
      parent or guardian and You are aware that Your child has provided Us with Personal Data
      without your permission, please contact Us. If We become aware that We have collected Personal
      Data from anyone under the age of 13 without verification of parental consent, We take steps
      to remove that information from our servers.
    </Text>
    <Heading my={4} as={'h2'}>
      Changes To Our Privacy Policy
    </Heading>
    <Text my={2}>
      If we decide to change our privacy policy, we will post those changes on this page, and/or
      update the Privacy Policy modification date below.
    </Text>
    <Heading my={4} as={'h2'}>
      Third-Party Services
    </Heading>
    <Text my={2}>
      We may display, include or make available third-party content (including data, information,
      applications and other products services) or provide links to third-party websites or services
      ("Third- Party Services").
    </Text>
    <Text my={2}>
      You acknowledge and agree that we shall not be responsible for any Third-Party Services,
      including their accuracy, completeness, timeliness, validity, copyright compliance, legality,
      decency, quality or any other aspect thereof. We do not assume and shall not have any
      liability or responsibility to you or any other person or entity for any Third-Party Services.
    </Text>
    <Text my={2}>
      Third-Party Services and links thereto are provided solely as a convenience to you and you
      access and use them entirely at your own risk and subject to such third parties' terms and
      conditions.
    </Text>
    <Heading my={4} as={'h2'}>
      Facebook Pixel
    </Heading>
    <Text my={2}>
      Facebook pixel is an analytics tool that allows you to measure the effectiveness of your
      advertising by understanding the actions people take on your website. You can use the pixel
      to: Make sure your ads are shown to the right people. Facebook pixel may collect information
      from your device when you use the service. Facebook pixel collects information that is held in
      accordance with its Privacy Policy.
    </Text>
    <Heading my={4} as={'h2'}>
      Tracking Technologies
    </Heading>
    <Box my={2} variant={'list'}>
      Cookies: We use Cookies to enhance the performance and functionality of our service but are
      non-essential to their use. However, without these cookies, certain functionality like videos
      may become unavailable or you would be required to enter your login details every time you
      visit our service as we would not be able to remember that you had logged in previously.
    </Box>
    <Box my={2} variant={'list'}>
      Local Storage: Local Storage sometimes known as DOM storage, provides web apps with methods
      and protocols for storing client-side data. Web storage supports persistent data storage,
      similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP
      request header.
    </Box>
    <Box my={2} variant={'list'}>
      Sessions: We use "Sessions" to identify the areas of our website that you have visited. A
      Session is a small piece of data stored on your computer or mobile device by your web browser.
    </Box>
    <Heading my={4} as={'h2'}>
      Information about General Data Protection Regulation (GDPR)
    </Heading>
    <Text my={2}>
      We may be collecting and using information from you if you are from the European Economic Area
      (EEA), and in this section of our Privacy Policy we are going to explain exactly how and why
      is this data collected, and how we maintain this data under protection from being replicated
      or used in the wrong way.
    </Text>
    <Heading my={4} as={'h2'}>
      What is GDPR?
    </Heading>
    <Text my={2}>
      GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is
      protected by companies and enhances the control the EU residents have, over their personal
      data.
    </Text>
    <Heading my={4} as={'h2'}>
      What is personal data?
    </Heading>
    <Text my={2}>
      Any data that relates to an identifiable or identified individual. GDPR covers a broad
      spectrum of information that could be used on its own, or in combination with other pieces of
      information, to identify a person. Personal data extends beyond a person’s name or email
      address. Some examples include financial information, political opinions, genetic data,
      biometric data, IP addresses, physical address, sexual orientation, and ethnicity. The Data
      Protection Principles include requirements such as:
    </Text>
    <Box my={2} variant={'list'}>
      Personal data collected must be processed in a fair, legal, and transparent way and should
      only be used in a way that a person would reasonably expect.
    </Box>
    <Box my={2} variant={'list'}>
      Personal data should only be collected to fulfil a specific purpose and it should only be used
      for that purpose. Organizations must specify why they need the personal data when they collect
      it.
    </Box>
    <Box my={2} variant={'list'}>
      Personal data should be held no longer than necessary to fulfil its purpose. People covered by
      the GDPR have the right to access their own personal data. They can also request a copy of
      their data, and that their data be updated, deleted, restricted, or moved to another
      organization.
    </Box>
    <Box my={2} variant={'list'}>
      People covered by the GDPR have the right to access their own personal data. They can also
      request a copy of their data, and that their data be updated, deleted, restricted, or moved to
      another organization.
    </Box>
    <Heading my={4} as={'h2'}>
      Why is GDPR important?
    </Heading>
    <Text my={2}>
      GDPR adds some new requirements regarding how companies should protect individuals' personal
      data that they collect and process. It also raises the stakes for compliance by increasing
      enforcement and imposing greater fines for breach. Beyond these facts it's simply the right
      thing to do. At Help Scout we strongly believe that your data privacy is very important and we
      already have solid security and privacy practices in place that go beyond the requirements of
      this new regulation.
    </Text>
    <Heading my={4} as={'h2'}>
      Individual Data Subject's Rights - Data Access, Portability and Deletion
    </Heading>
    <Text my={2}>
      We are committed to helping our customers meet the data subject rights requirements of GDPR.
      We process or store all personal data in fully vetted, DPA compliant vendors. We do store all
      conversation and personal data for up to 6 years unless your account is deleted. In which
      case, we dispose of all data in accordance with our Terms of Service and Privacy Policy, but
      we will not hold it longer than 60 days.
    </Text>
    <Text my={2}>
      We are aware that if you are working with EU customers, you need to be able to provide them
      with the ability to access, update, retrieve and remove personal data. We got you! We've been
      set up as self service from the start and have always given you access to your data and your
      customers data. Our customer support team is here for you to answer any questions you might
      have about working with the API.
    </Text>
    <Heading my={4} as={'h2'}>
      California Residents
    </Heading>
    <Text my={2}>
      The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal
      Information we collect and how we use it, the categories of sources from whom we collect
      Personal Information, and the third parties with whom we share it, which we have explained
      above.
    </Text>
    <Text my={2}>
      We are also required to communicate information about rights California residents have under
      California law. You may exercise the following rights:
    </Text>
    <Box my={2} variant={'list'}>
      Right to Know and Access. You may submit a verifiable request for information regarding the:
      (1) categories of Personal Information we collect, use, or share; (2) purposes for which
      categories of Personal Information are collected or used by us; (3) categories of sources from
      which we collect Personal Information; and (4) specific pieces of Personal Information we have
      collected about you.
    </Box>
    <Box my={2} variant={'list'}>
      Right to Equal Service. We will not discriminate against you if you exercise your privacy
      rights.
    </Box>
    <Box my={2} variant={'list'}>
      Right to Delete. You may submit a verifiable request to close your account and we will delete
      Personal Information about you that we have collected.
    </Box>
    <Box my={2} variant={'list'}>
      Request that a business that sells a consumer's personal data, not sell the consumer's
      personal data.
    </Box>
    <Text my={2}>
      If you make a request, we have one month to respond to you. If you would like to exercise any
      of these rights, please contact us.
    </Text>
    <Text my={2}>We do not sell the Personal Information of our users.</Text>
    <Text my={2}>For more information about these rights, please contact us.</Text>
    <Heading my={4} as={'h2'}>
      California Online Privacy Protection Act (CalOPPA)
    </Heading>
    <Text my={2}>
      CalOPPA requires us to disclose categories of Personal Information we collect and how we use
      it, the categories of sources from whom we collect Personal Information, and the third parties
      with whom we share it, which we have explained above.
    </Text>
    <Heading my={4} as={'h2'}>
      CalOPPA users have the following rights:
    </Heading>
    <Text my={2}>
      CalOPPA requires us to disclose categories of Personal Information we collect and how we use
      it, the categories of sources from whom we collect Personal Information, and the third parties
      with whom we share it, which we have explained above.
    </Text>
    <Box my={2} variant={'list'}>
      Right to Know and Access. You may submit a verifiable request for information regarding the:
      (1) categories of Personal Information we collect, use, or share; (2) purposes for which
      categories of Personal Information are collected or used by us; (3) categories of sources from
      which we collect Personal Information; and (4) specific pieces of Personal Information we have
      collected about you.
    </Box>
    <Box my={2} variant={'list'}>
      Right to Equal Service. We will not discriminate against you if you exercise your privacy
      rights.
    </Box>
    <Box my={2} variant={'list'}>
      Right to Delete. You may submit a verifiable request to close your account and we will delete
      Personal Information about you that we have collected.
    </Box>
    <Box my={2} variant={'list'}>
      Right to request that a business that sells a consumer's personal data, not sell the
      consumer's personal data.
    </Box>
    <Text my={3}>
      If you make a request, we have one month to respond to you. If you would like to exercise any
      of these rights, please contact us.
    </Text>
    <Text my={3}>We do not sell the Personal Information of our users.</Text>
    <Text my={3}>For more information about these rights, please contact us.</Text>
    <Heading my={4} as={'h2'}>
      Contact Us
    </Heading>
    <Text my={3}>Don't hesitate to contact us if you have any questions.</Text>
    <Box my={2} variant={'list'}>
      Via Email: <Link href="mailto:3dreactprj@gmail.com">3dreactprj@gmail.com</Link>
    </Box>
    <Box my={2} variant={'list'}>
      Via this Link: <RouterLink to="/about"> https://3dreact.com/about</RouterLink>
    </Box>
  </Box>
);

export default PrivacyPolicy;
